import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Site Under Construction
        </p>
        <p>Coming Soon...</p>
      </header>
    </div>
  );
}

export default App;
